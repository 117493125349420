import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { PageLayout, ProductList, PageBreadcrumb } from '../../components'

const GreenFridayProductPageTemplate = ({ data, pageContext }) => {
  const { lang, page } = pageContext

  const items = data.allShopProduct
    ? data.allShopProduct.edges.map(({ node }) => node)
    : []

  const navigation = {
    page: 0
  }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : 0
  }

  return (
    <PageLayout lang={lang} switcher={page}>
      <div className="product-list-4 product-list-breadcrumbs">
        <PageBreadcrumb
          lang={lang}
          current={page}
          items={[ 'homepage' ]}
          />

        <ProductList
          title={page[`title_${lang.suffix}`]}
          products={items}
          attributes={data.allShopAttribute.edges.map(i => i.node)}
          attribute_groups={data.allShopAttributeGroup.edges.map(i => i.node)}
          lang={lang}
          navigation={navigation}
          keyPrefix="greenfriday" />
      </div>
    </PageLayout>
  )
}

export default GreenFridayProductPageTemplate

export const query = graphql`
  query GreenFridayProductPageTemplateQuery {
    allShopProduct(filter: {flags: {eq: "greenfriday"}}) {
      edges {
        node {
          ...ProductSearchItem
        }
      }
    }
    allShopAttributeGroup {
      edges {
        node {
          group_type
          id_attribute_group
          is_color_group
          name_en
          name_nl
          position
          public_name_en
          public_name_nl
        }
      }
    }
    allShopAttribute {
      edges {
        node {
          id_attribute
          id_attribute_group
          color
          name_en
          name_nl
          position
        }
      }
    }
  }
`
